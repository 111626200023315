import { IStatusHeader, StatusHeader } from '~/components/atoms/StatusHeader'
import { LoadingInput } from '../LoadingInput'
import * as S from './styles'

interface IStatus {
  title: IStatusHeader['title'] | null
  type: IStatusHeader['type']
  content: string | JSX.Element | null
  showLogoOnDesktop?: boolean
  showIcon?: boolean
}

export const Status = ({ title, content, type, showLogoOnDesktop, showIcon }: IStatus) => {
  return (
    <S.StatusContainer>
      <StatusHeader title={title} type={type} showLogoOnDesktop={showLogoOnDesktop} showIcon={showIcon} />
      <S.StatusContent>
        {!content ? <LoadingInput hasLabel={false} /> : <S.StatusText as="span">{content}</S.StatusText>}
      </S.StatusContent>
    </S.StatusContainer>
  )
}
