import type { NextPage } from 'next'
import { useEffect } from 'react'
import { InvalidRequestScreen } from '~/components/templates/InvalidRequestScreen'
import { MainPublic } from '~/components/templates/MainPublic'
import { SessionStorageKeys } from '~/enums/sessionStorageKeys'
import { asyncSessionStorage } from '~/utils/asyncSessionStorage'

const Home: NextPage = () => {
  const setInvalidRequestData = asyncSessionStorage.setItem(
    SessionStorageKeys.invalidRequestData,
    JSON.stringify({ type: 'publicToken' }),
  )

  useEffect(() => {
    async function invalidToken() {
      await setInvalidRequestData
    }
    invalidToken()
  }, [setInvalidRequestData])

  return (
    <MainPublic>
      <InvalidRequestScreen />
    </MainPublic>
  )
}

export default Home
