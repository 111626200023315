import styled from 'styled-components'
import { Body2 } from '~/styles/global'

export const StatusContainer = styled.div``

export const StatusContent = styled.div`
  padding: 24px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 24px;
  }
`

export const StatusText = styled(Body2)`
  max-width: 496px;
`
