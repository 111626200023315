import { IInvalidRequestData } from '~/@types/IInvalidRequest'

export const invalidRequestData = {
  publicToken: {
    title: 'O token informado não foi encontrado ou está expirado',
    content: () => 'Verifique se o link acessado está correto com a pessoa que te indicou como avalista.',
  },
  CPF: {
    title: 'CPF não cadastrado',
    content: (data: IInvalidRequestData) =>
      `Ops, parece que você não foi cadastrado como avalista de crédito. Entre em contato com ${data?.userFirstName}.`,
  },
}
