import { useEffect, useState } from 'react'
import { IInvalidRequestData } from '~/@types/IInvalidRequest'
import { SessionStorageKeys } from '~/enums/sessionStorageKeys'
import { asyncSessionStorage } from '~/utils/asyncSessionStorage'
import { invalidRequestData } from './constants'

export const useToken = () => {
  const [customData, setCustomData] = useState<IInvalidRequestData | null>(null)
  const [title, setTitle] = useState<string | null>(null)
  const [content, setContent] = useState<string | null>(null)

  useEffect(() => {
    const fetchCustomData = async () => {
      const customDataFromSessionStorage = await asyncSessionStorage.getItem(SessionStorageKeys.invalidRequestData)

      if (customDataFromSessionStorage) {
        setCustomData(JSON.parse(customDataFromSessionStorage))
      }
    }

    fetchCustomData()
  }, [])

  useEffect(() => {
    const data = customData?.type && invalidRequestData[customData.type]

    if (data) {
      setTitle(data.title)
      setContent(() => data.content(customData))
      return
    }

    setTitle('Houve um erro')
    setContent('Por favor, tente novamente mais tarde.')
  }, [customData?.type, customData])

  return {
    title,
    content,
  }
}
